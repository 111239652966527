import React, { useEffect, useState, useMemo } from 'react';
import { useMediaQuery } from "react-responsive";

import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import ClearIcon from '@mui/icons-material/Clear';


import { Link } from "react-router-dom";

import clsx from 'clsx';
import '../../src/Styles.css';
import AndriodBage from '../assets/google-play-badge.png'
import IosBage from '../assets/iosBage.png'

const HomeDrawer = () => {

  const [open, setOpen] = useState(false);
  const [themeTextColor, setTheme] = useState(null);
  const [themeMainTextColor, setThemeMainTextColor] = useState(null);


  const useStyles = styled(theme => ({
    root: {
      display: 'flex',
    },

    title: {
      flexGrow: 1,
    },

    hide: {
      display: 'none',
    },

    paper: {
      background: themeMainTextColor,
    },

    textColor: {
      color: themeTextColor
    },

    moveToLeft: {
      marginLeft: '65%'
    }
  }));

  const classes = useStyles();
  // const theme = useTheme();



  const drawerWidth = 180;
  const isDark = undefined;
  // Detecting the default theme
  const systemPrefersDark = useMediaQuery(
    {
      query: "(prefers-color-scheme: dark)",
    },
    undefined
  );


  const value = useMemo(
    () => (isDark === undefined ? !!systemPrefersDark : isDark),
    [isDark, systemPrefersDark]
  );

  useEffect(() => {
    if (value) {
      setTheme('#fff')
      setThemeMainTextColor('#111')
    } else {
      setTheme('#111')
      setThemeMainTextColor('#fff')

    }
  }, [value]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function getMobileOperatingSystem() {

    // Get the user agent string from the browser
    const userAgent = navigator.userAgent;

    // Check if the user agent string contains keywords for different operating systems
    if (userAgent.match(/Windows/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      window.open('https://apps.apple.com/us/app/yana/id1665001650', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/Android/i)) {
      window.open('https://play.google.com/store/apps/details?id=com.uyana.scripts.app.gy', "_blank", "noreferrer");
      return null;
    } else if (userAgent.match(/iOS/i)) {
      window.open('https://apps.apple.com/us/app/yana/id1665001650', "_blank", "noreferrer");
      return null;
    } else {
      return null
    }
  }


  return (
    <div>
      <div className='blog-icon'>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={clsx(open && classes.hide)}
        >
          <MenuIcon style={{ fontSize: 40, color: themeTextColor }} />

        </IconButton>
      </div>

      <Drawer
        //className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{ paper: classes.paper }}

      >
        <div  >
          <IconButton onClick={handleDrawerClose} classes={{ root: classes.moveToLeft }}>
            <ClearIcon style={{ color: '#111', fontSize: 37, }} />
          </IconButton>

        </div>

        <List>

          <div onClick={() => {
            window.location.href = 'https://caribematch.com/dating-tips'
            handleDrawerClose()
          }} style={{ textDecoration: 'none', cursor: 'pointer' }} className="App-link">
            <ListItem >
              <ListItemText primary={'Dating Tips'} classes={{ primary: classes.textColor }} />
            </ListItem>
          </div>

          <div onClick={() => {
            window.location.href = 'https://caribematch.com/safety-tips'
            handleDrawerClose()
          }} style={{ textDecoration: 'none', cursor: 'pointer' }} className="App-link">
            <ListItem >
              <ListItemText primary={'Dating Safety Tips'} classes={{ primary: classes.textColor }} />
            </ListItem>
          </div>

          <div onClick={() => {
            window.location.href = 'https://caribematch.com/privacy-policy'
            handleDrawerClose()
          }} style={{ textDecoration: 'none', cursor: 'pointer' }} className="App-link">
            <ListItem >
              <ListItemText primary={'Privacy Policy'} classes={{ primary: classes.textColor }} />
            </ListItem>
          </div>

          <div onClick={() => {
            window.location.href = 'https://caribematch.com/terms-conditions'
            handleDrawerClose()
          }} style={{ textDecoration: 'none', cursor: 'pointer' }} className="App-link">
            <ListItem >
              <ListItemText primary={'Terms & Conditions'} classes={{ primary: classes.textColor }} />
            </ListItem>
          </div>

          <div onClick={() => {
            window.location.href = 'https://caribematch.com/contact-us'
            handleDrawerClose()
          }} style={{ textDecoration: 'none', cursor: 'pointer' }} className="App-link">
            <ListItem >
              <ListItemText primary={'Contact Us'} classes={{ primary: classes.textColor }} />
            </ListItem>
          </div>

          <ListItem >
            <div onClick={getMobileOperatingSystem}>
              <img src={AndriodBage} alt="Trulli" width="155" height="75"></img>
            </div>
          </ListItem>

          <ListItem >
            <div onClick={getMobileOperatingSystem} style={{ marginLeft: '3%' }}>
              <img src={IosBage} alt="Trulli" width="150" height="50"></img>
            </div>
          </ListItem>
        </List>

      </Drawer>


    </div>
  );
}

export default HomeDrawer
